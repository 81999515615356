<template>
  <f7-page class="scan-page">
    <f7-navbar back-link="Back"> </f7-navbar>

    <section class="custom-container">
      <div class="container">
        <p><img src="@/assets/images/wechat.png" loading="lazy" /></p>
        <h1>Loading...</h1>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, computed } from "vue";
import { configs } from "@/utils/configs.js";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";
export default defineComponent({
  name: "WechatRedictPage",
  components: {},
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore();
    const redirect = async (code) => {
      let mode = props?.f7route?.params?.mode;
      let url = configs.baseURL;
      let miniApp = false;
      let isWechat = helpers.isInsideWeixin();

      if (mode == "local") {
        url = configs.baseURLInfo.local;
      } else if (mode == "development") {
        url = configs.baseURLInfo.development;
      } else if (mode == "mini") {
        miniApp = true;
        url = `https://${window.location.hostname}/api`;
      }

      url = `${url}/webhook/${miniApp ? "wechat/mini" : "wechat"}?host=${props?.f7route?.query?.host}&code=${props?.f7route?.query?.code}${isWechat ? "&wechat=1" : ""}`;

      setTimeout(() => {
        window.location.href = url;
      }, 1000);
    };

    onMounted(async () => {
      setTimeout(() => {
        redirect();
      }, 800);
    });
    return {};
  },
});
</script>

<style scoped>
.container > h1 {
  text-align: center;
}
section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
p {
  text-align: center;
  padding: 0px;
  margin: 0px;
}
.container > p > img {
  width: 100px;
}
</style>
